define('ember-document-title/helpers/title', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var get = _ember['default'].get;
  var guidFor = _ember['default'].guidFor;

  function updateTitle(tokens) {
    document.title = tokens.toString();
  }

  exports['default'] = _ember['default'].Helper.extend({
    documentTitleList: _ember['default'].inject.service(),

    init: function init() {
      this._super();
      var tokens = get(this, 'documentTitleList');
      tokens.push({ id: guidFor(this) });
    },

    compute: function compute(params, hash) {
      if (hash.separator == null) {
        hash.separator = ' | ';
      }

      var tokens = get(this, 'documentTitleList');
      hash.id = guidFor(this);
      hash.title = params.join('');
      tokens.push(hash);
      _ember['default'].run.scheduleOnce('afterRender', null, updateTitle, tokens);
      return '';
    },

    destroy: function destroy() {
      var tokens = get(this, 'documentTitleList');
      var id = guidFor(this);
      tokens.remove(id);
      _ember['default'].run.scheduleOnce('afterRender', null, updateTitle, tokens);
    }
  });
});